a {
  color: #E81D0D;
  font-weight: bold;
}

button {
/*  margin: 4px; */
  width: 180px;
  border: #E81D0D 2px solid;
  background: #E81D0D;
  text-decoration: none;
  position: relative;
  line-height: 30px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
button:hover {
  opacity: 0.7;
}

button.important {
  color: #E81D0D;
  border: #E81D0D 2px solid;
  background: #ffffff;
}
button.important:hover {
  opacity: 0.7;
  color: #ffffff;
  background: #E81D0D;
}

button.success {
  color: #11CA11;
  border: #11CA11 2px solid;
  background: #ffffff;
}
button.success:hover {
  opacity: 0.7;
  color: #ffffff;
  background: #11CA11;
}


ul {
  margin-left: 24px;
}


.nonimportant {
  font-size: small;
}

.imgbutton {
  background: none;
  border: none;
  vertical-align: middle;
}


.form-label {
  font-weight: bold;
  font-size: 16px;
}

.form-required:after {
  content: ' *';
  color: #E81D0D;
}


.upperIndex {
  font-variant-position: super;
}


.flag-icons img {
  margin-right: 10px;
  max-height: 20px;
  width: auto;
}

ul.transportdates {
  margin-top: 20px;
  font-weight: bold;
  font-size: 20px;
  list-style-type: none;
}


.blog-article .blog-img img {
  width: 100%;
  margin-bottom: 20px;
}

.blog-desc img {
  height: auto;
  width: 100%;
}

.blog-left, .blog-right {
  margin: 20px;
}
img.blog-left, img.blog-right {
  width: auto;
}
.blog-left {
  float: left;
  margin-left: 0;
}
.blog-right {
  float: right;
  margin-right: 0;
}

#blog-article .section-title {
  margin: 30px 0 10px 0;
}

#blog-article ol, #blog-article ul {
  margin-left: 50px;
}

.blog-heading {
  text-align: center;
}

.blog-button, #blog-article .blog-goback {
  display: flex;
  justify-content: center;
  align-items: center;
}

#blog-article .blog-goback:hover {
  text-decoration: none !important;
}


.blog-quiz-question-text img {
  height: auto !important;
  width: 20% !important;
  float: left;

  margin-right: 20px;
}

.blog-quiz-question-counter, .blog-quizcorrect-text {
  font-weight: bold !important;
}

.blog-quiz-question-text {
  padding: 0 40px 10px 40px;
  clear: both;
}

.blog-quiz-question-answers, .blog-quizcorrect li {
  clear: both;
  /*display: flex;
  flex: 1 1 auto;
  justify-content: center;*/
  padding-left: 30%;
}

.blog-quiz ol {
  list-style-type: upper-alpha;
}
.blog-quizcorrect ol {
  margin: 50px 0;
  list-style-type:none;
  color: #999;
}

#contact-form .row {
  line-height: inherit;
}

#contact-form .packages {
  margin: 0 0 40px 0;
}

#contact-form .packages label {
  font-size: 12px;
  line-height: 20px;
}

#contact-form .packages .row {
  margin: 10px 0;
  line-height: 36px;
}

#contact-form .packages .items button {
  width: 100%;
  padding: 28px 0;
}


.bar {
  display: block;
  width: 100%;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4em;
}
.bar:hover {
  opacity: 0.9;
  text-decoration: none;
}


.alert-box {
  background: #f7f744;
}
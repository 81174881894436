* {
    padding: 0;
    margin: 0;
}
body {
    font-family: 'Barlow', sans-serif;
}
.contact-top {
    line-height: 35px;
    height: auto;
}
.contact-top .phone-contact {
    display: inline-block;
    flex-wrap: wrap;
    
    font-weight: 600;
    
    
    margin-left: 15px;
    font-size: 13px;
    color: #5B5F71;
}
.contact-top .phone-contact span:nth-of-type(2) { margin-left: 10px;}
.contact-top .phone-contact span:nth-of-type(3) {margin-left: 15px;}

/* </---contact-top---> */
#navigation {
    line-height: 50px;
}
.nav-item .nav-link {
    font-family: 'Barlow', sans-serif;
    font-weight: bold;
    color: #14192D;
    margin: 0 5px;
}
#objednat_prepravu{
    margin-right: -35px;
}
#objednat_prepravu button {

    background: #E81D0D;
    color: #ffffff;
    border: none;
    height: 50px;
    width: 195px;
    line-height: 35px;
    text-transform: uppercase;
    font-weight: bold;
}

.nav-item .nav-link.active {
    color: #E81D0D;
}
.language-set img {
    height: 13px;
    width: auto;
}












/* HOVERS */
.nav-item .nav-link:hover {
    color: #E81D0D;
}
#objednat_prepravu button:hover {
    opacity: 0.7;
}